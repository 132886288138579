/**
 * --------------------------------------------------------------------------
 * Vsyahimiya (v1.0.0): clock.js
 * Licensed under MIT (https://vsyahimiya.ru/main/LICENSE.md)
 * --------------------------------------------------------------------------
 */

import EventHandler from 'bootstrap/js/src/dom/event-handler.js'
import BaseComponent from 'bootstrap/js/src/base-component.js'
import SelectorEngine from 'bootstrap/js/src/dom/selector-engine.js'

/**
 * Константы
 */
const NAME = 'сlock'
const DATA_KEY = 'bs.сlock'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const EVENT_LOAD_DATA_API = `load${EVENT_KEY}${DATA_API_KEY}`

const SELECTOR_DATA_API = '[data-bs-target="сlock"]'

class Clock extends BaseComponent {
  constructor (element) {
    super(element)

    this._timer = ''
  }

  static get NAME () {
    return NAME
  }

  render () {
    if (this._element) {
      let date = new Date()

      this._element.innerHTML = date.toLocaleTimeString()
    }
  }

  stop () {
    clearInterval(this._timer)
  }

  start () {
    this.render()
    this._timer = setInterval(() => this.render(), 1000)
  }
}

EventHandler.on(window, EVENT_LOAD_DATA_API, () => {
  for (const selector of SelectorEngine.find(SELECTOR_DATA_API)) {
    Clock.getOrCreateInstance(selector).start()
  }
})

export default Clock