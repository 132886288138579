/**
 * --------------------------------------------------------------------------
 * Vsyahimiya (v1.0.0): toaster.js
 * Licensed under MIT (https://vsyahimiya.ru/main/LICENSE.md)
 * --------------------------------------------------------------------------
 */

import BaseComponent from 'bootstrap/js/src/base-component.js'
import TemplateFactory from 'bootstrap/js/src/util/template-factory.js'
import EventHandler from 'bootstrap/js/src/dom/event-handler.js'

const NAME = 'toaster'

const TOAST_POSITION = {
  TOP_START    : 'top-0 start-0',
  TOP_CENTER   : 'top-0 start-50 translate-middle-x',
  TOP_END      : 'top-0 end-0',
  BOTTOM_START : 'bottom-0 start-0',
  BOTTOM_CENTER: 'bottom-0 start-50 translate-middle-x',
  BOTTOM_END   : 'bottom-0 end-0', // По умолчанию
  CENTER_START : 'top-50 start-0 translate-middle-y',
  CENTER_END   : 'top-50 end-0 translate-middle-y',
  CENTER       : 'top-50 start-50 translate-middle',
}

const TOAST_TYPE = {
  DEFAULT: '', // По умолчанию
  PRIMARY: 'primary',
  INFO   : 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER : 'danger',
  DARK   : 'dark',
}

const TOAST_TIMER = {
  DISABLED : 0,
  ELAPSED  : 1, // По умолчанию
  COUNTDOWN: 2,
}

const TOAST_TEMPLATE = {
  DEFAULT: `<div class="toast align-items-center text-bg-primary border-0" role="alert" aria-live="assertive" aria-atomic="true">
              <div class="d-flex">
                <div class="toast-body"></div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Закрыть"></button>
              </div>
            </div>`,
  CUSTOM : `<div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
             <div class="toast-header">
               <div class="toast-title"></div>
               <strong class="me-auto"></strong>
               <small class="toast-timer text-body-secondary"></small>
               <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Закрыть"></button>
             </div>
             <div class="toast-body"></div>
           </div>`,
}

const Default = {
  position     : TOAST_POSITION.BOTTOM_END,
  type         : TOAST_TYPE.DEFAULT,
  bg           : false,
  timer        : TOAST_TIMER.COUNTDOWN,
  delay        : 6000,
  defaultIcon  : `<i class="bi bi-bell mt-1 me-2"></i>`,
  animation    : true,
  template     : TOAST_TEMPLATE.DEFAULT,
  customContent: {},
}

const DefaultType = {
  position     : 'string',
  type         : 'string',
  bg           : 'boolean',
  timer        : 'number',
  delay        : 'number',
  defaultIcon  : 'string',
  animation    : 'boolean',
  template     : 'string',
  customContent: 'object',
}

class Toaster extends BaseComponent {
  constructor (config) {
    super()
    this._config = this._getConfig(config)
    this.toastContainer = this._getOrCreateContainer()
    document.body.appendChild(this.toastContainer)
  }

  static get NAME () {
    return NAME
  }

  static get Default () {
    return Default
  }

  static get DefaultType () {
    return DefaultType
  }

  create (title = 'title', content = 'content', options = {}) {
    const config = { ...this._config, ...options }
    const {
      type,
      timer,
      delay,
      animation,
      template,
      defaultIcon,
      bg,
      customContent,
    } = config

    const extraClass = type === TOAST_TYPE.DEFAULT
      ? customContent.classes || ''
      : `${bg ? `text-bg-${type}` : `border-${type}`} ${customContent.classes ||
      ''}`

    const templateFactory = new TemplateFactory({
      content   : {
        '.toast-icon' : defaultIcon,
        '.toast-title': title,
        '.toast-body' : content,
        ...customContent.content,
      },
      extraClass: extraClass.trim(),
      sanitize  : false,
      html      : true,
      template,
    })

    if (!templateFactory) {
      return null
    }

    const toastElement = templateFactory.toHtml()
    this._setupToastDataAttributes(toastElement, delay, animation)

    const timerNode = toastElement.querySelector('.toast-timer')
    if (timerNode) {
      this._setupTimer(timer, delay, timerNode, toastElement)
    }

    this._renderToast(toastElement)
  }

  _getOrCreateContainer () {
    const containerId = this._config.position
    let containerNode = document.querySelector(
      `[data-bs-toast="${containerId}"]`)

    if (!containerNode) {
      containerNode = this._createContainerNode()
      containerNode.dataset.bsToast = containerId
    }

    return containerNode
  }

  _createContainerNode () {
    const containerNode = new DOMParser().parseFromString(
      `<div class="toast-container position-fixed m-3" aria-live="polite" style="z-index:1000036;"></div>`,
      'text/html').body.firstChild
    containerNode.classList.add(...this._config.position.split(' '))
    return containerNode
  }

  _setupToastDataAttributes (toastElement, delay, animation) {
    toastElement.dataset.bsAutohide = (Number.isInteger(delay) && delay >
      0).toString()
    toastElement.dataset.bsDelay = delay.toString()
    toastElement.dataset.bsAnimation = animation.toString()
  }

  _setupTimer (timerOption, delay, timerNode, toastElement) {
    if (timerOption === TOAST_TIMER.ELAPSED) {
      this._startElapsedTimer(timerNode, toastElement)
    } else if (timerOption === TOAST_TIMER.COUNTDOWN && delay > 0) {
      this._startCountdownTimer(timerNode, delay, toastElement)
    } else {
      timerNode.remove()
    }
  }

  _startElapsedTimer (timerNode, toastElement) {
    timerNode.innerText = 'прямо сейчас'
    let minutes = 1
    const timerInterval = setInterval(() => {
      if (toastElement.classList.contains('show')) {
        timerNode.innerText = this._pluralize(minutes,
          ['минута', 'минуты', 'минут'])
        minutes++
      } else {
        clearInterval(timerInterval)
      }
    }, 60 * 1000)

    EventHandler.on(toastElement, 'hidden.bs.toast',
      () => clearInterval(timerInterval))
  }

  _startCountdownTimer (timerNode, delay, toastElement) {
    let seconds = Math.max(delay / 1000, 0) // Убедитесь, что значение не отрицательное
    const updateTimer = () => {
      if (seconds >= 0) {
        timerNode.innerText = this._pluralize(seconds,
          ['секунда', 'секунды', 'секунд'])
        seconds--
      } else {
        timerNode.innerText = 'Время истекло'
        clearInterval(countdownInterval)
      }
    }

    updateTimer()
    const countdownInterval = setInterval(() => {
      if (toastElement.classList.contains('show')) {
        updateTimer()
      } else {
        clearInterval(countdownInterval)
      }
    }, 1000)

    EventHandler.on(toastElement, 'hidden.bs.toast',
      () => clearInterval(countdownInterval))
  }

  _renderToast (toastContent) {
    this.toastContainer.appendChild(toastContent)
    EventHandler.on(toastContent, 'hidden.bs.toast',
      () => toastContent.remove())
    const toast = new bootstrap.Toast(toastContent)
    toast.show()
  }

  _pluralize (number, [singular, few, many]) {
    if (number === 1) {
      return `${number} ${singular}`
    } else if (number >= 2 && number <= 4) {
      return `${number} ${few}`
    } else {
      return `${number} ${many}`
    }
  }
}

export {
  Toaster,
  TOAST_TYPE as ToasterType,
  TOAST_POSITION as ToasterPosition,
  TOAST_TIMER as ToasterTimer,
  TOAST_TEMPLATE as ToasterTemplate,
}

export default Toaster





