/**
 * --------------------------------------------------------------------------
 * Vsyahimiya (v1.0.0): ajax-forms.js
 * Licensed under MIT (https://vsyahimiya.ru/main/LICENSE.md)
 * --------------------------------------------------------------------------
 */

import EventHandler from 'bootstrap/js/src/dom/event-handler.js'
import BaseComponent from 'bootstrap/js/src/base-component.js'
import SelectorEngine from 'bootstrap/js/src/dom/selector-engine.js'
import Toaster, {
  ToasterType,
  ToasterPosition,
} from './toaster.js'

const NAME = 'ajax-forms'
const DATA_KEY = 'bs.ajaxForms'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`
const EVENT_LOAD_DATA_API = `load${EVENT_KEY}${DATA_API_KEY}`

const SELECTOR_DATA_API = '[data-bs-target="ajax-forms"]'
const SELECTOR_DATA_API_EMAIL_CONFIRMATION = '[data-bs-target="resend-email-confirmation"]'

const ToasterTemplate = `<div class="toast align-items-center text-bg-primary border-0" role="alert" aria-live="assertive" aria-atomic="true">
              <div class="d-flex align-items-center p-2">
                <span class="toast-icon fs-5"></span>
                <div class="toast-body me-2 text-break"></div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Закрыть"></button>
              </div>
            </div>`

class AjaxForms extends BaseComponent {
  constructor (element, config) {
    super(element, config)

    this.toast = new Toaster({
      bg         : true,
      template   : ToasterTemplate,
      position   : ToasterPosition.TOP_END,
      defaultIcon: '<i class="bi bi-bell"></i>',
    })

    if (this._element) {
      this._element.setAttribute('novalidate', '')
      this._element.classList.add('needs-validation')
      this._init()
    }
  }

  static get NAME () {
    return NAME
  }

  _init () {
    this._handleSubmit()
  }

  _handleSendEmailConfirmation () {
    const selectorSendEmailConfirmation = SelectorEngine.findOne(
      SELECTOR_DATA_API_EMAIL_CONFIRMATION, this._element)

    if (selectorSendEmailConfirmation) {
      EventHandler.on(selectorSendEmailConfirmation, 'click', async (event) => {
        event.preventDefault()

        const userId = selectorSendEmailConfirmation.getAttribute('data-user-id')
        const result = await this._response(`/admin/users/${userId}/resend-email-confirmation`)

        if (result.success) {
          this.toast.create('', result.message ?? 'Успех',
            { type: ToasterType.SUCCESS })
        } else {
          this.toast.create('', result.error ?? result.message ?? 'Ошибка',
            { type: ToasterType.DANGER })
        }
      })
    }
  }

  _handleSubmit () {
    EventHandler.on(this._element, 'submit', async (event) => {
      event.preventDefault()

      if (!this._element.checkValidity()) {
        event.stopPropagation()
      } else {
        this._updateSubmitButtonStatus(true)

        const formData = this._getDataForm(this._element)
        const result = await this._response(this._element.action, formData._method, formData)

        this._updateSubmitButtonStatus(false)

        if (result.success) {
          this.toast.create('', result.message ?? 'Успех', { type: ToasterType.SUCCESS })
          this._handleValidationErrors({})

          if (result.redirect) {
            setTimeout(() => window.location.href = result.redirect, 1000)
          }
        } else {
          if (result.errors && typeof result.errors === 'object' &&
            !Array.isArray(result.errors)) {
            let timeout = 4000

            for (const [field, messages] of Object.entries(result.errors)) {
              messages.forEach(message => {
                timeout += 2000

                this.toast.create('', message, { type: ToasterType.DANGER, delay: timeout })
              })
            }
          } else {
            this.toast.create('', result.error ?? result.message ?? 'Ошибка', { type: ToasterType.DANGER })
          }

          this._handleValidationErrors(result.errors ?? {})
          this._updateSubmitButtonStatus(false)
        }
      }
    })
  }

  _handleValidationErrors (errors) {
    const errorFields = SelectorEngine.find('.is-invalid, .invalid-feedback',
      this._element)

    errorFields.forEach(field => {
      if (field.classList.contains('is-invalid')) {
        field.classList.remove('is-invalid')
        field.classList.remove('is-valid')
      }

      if (field.classList.contains('invalid-feedback')) {
        field.remove()
      }
    })

    if (Object.keys(errors).length > 0) {
      this._element.classList.remove('was-validated')
    } else {
      this._element.classList.add('was-validated')
    }

    for (const field in errors) {
      const inputField = SelectorEngine.findOne(`[name="${field}"]`,
        this._element)

      if (inputField) {
        inputField.classList.add('is-invalid')
        inputField.setAttribute('aria-describedby', `error-${field}`)

        const errorDiv = document.createElement('div')
        errorDiv.classList.add('invalid-feedback', 'd-block')
        errorDiv.id = `error-${field}`
        errorDiv.textContent = errors[field][0]

        const inputGroup = inputField.closest('.input-group')
        if (inputGroup) {
          inputGroup.insertAdjacentElement('afterend', errorDiv)
        } else {
          inputField.insertAdjacentElement('afterend', errorDiv)
        }
      }
    }

    if (Object.keys(errors).length === 0) {
      this._element.classList.remove('was-validated')
    }
  }

  _updateSubmitButtonStatus (disabled) {
    const submitButton = SelectorEngine.findOne('button[type="submit"]', this._element)

    if (submitButton) {
      submitButton.disabled = disabled
    }
  }

  _response = async (url = '', method = 'POST', body = {}) => {
    try {
      const fetchResponse = await fetch(url, {
        method     : method,
        credentials: 'same-origin',
        headers    : {
          'Content-Type' : 'application/json',
          'Cache-Control': 'no-cache',
          'Charset'      : 'UTF-8',
          'X-CSRF-TOKEN' : SelectorEngine.findOne('meta[name="csrf-token"]').getAttribute('content'),
        },
        body: JSON.stringify(body)
      })

      const data = await fetchResponse.json()

      return {
        ok        : fetchResponse.ok,
        status    : fetchResponse.status,
        statusText: fetchResponse.statusText,
        ...data,
      }
    } catch (error) {
      console.error('Ошибка:', error)
      return {
        ok        : false,
        status    : 500,
        statusText: 'Ошибка сети',
        message   : error.message,
      }
    }
  }

  _getDataForm(form) {
    const formData = new FormData(form);
    const formEntries = formData.entries();
    const data = {};

    for (const [key, value] of formEntries) {
      const normalizedKey = key.endsWith('[]') ? key.slice(0, -2) : key;

      if (data[normalizedKey] !== undefined) {
        if (Array.isArray(data[normalizedKey])) {
          data[normalizedKey].push(value);
        } else {
          data[normalizedKey] = [data[normalizedKey], value];
        }
      } else {
        data[normalizedKey] = key.endsWith('[]') ? [value] : value;
      }
    }

    for (const key in data) {
      if (Array.isArray(data[key]) && data[key].length === 0) {
        data[key] = [];
      }
    }

    return data;
  }
}

EventHandler.on(window, EVENT_LOAD_DATA_API, () => {
  for (const selector of SelectorEngine.find(SELECTOR_DATA_API)) {
    AjaxForms.getOrCreateInstance(selector)
  }
})
export default AjaxForms