/**
 * --------------------------------------------------------------------------
 * Vsyahimiya (v1.0.0): tooltip.js
 * Licensed under MIT (https://vsyahimiya.ru/main/LICENSE.md)
 * --------------------------------------------------------------------------
 */

import SelectorEngine from 'bootstrap/js/src/dom/selector-engine.js'
import EventHandler from 'bootstrap/js/src/dom/event-handler.js'

EventHandler.on(window, 'load', () => {
  for (const tooltip of SelectorEngine.find('[data-bs-target="tooltip"]')) {
    new bootstrap.Tooltip(tooltip)
  }
})
