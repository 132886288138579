/**
 * --------------------------------------------------------------------------
 * Vsyahimiya (v1.0.0): import-product.js
 * Licensed under MIT (https://vsyahimiya.ru/main/LICENSE.md)
 * --------------------------------------------------------------------------
 */

import EventHandler from 'bootstrap/js/src/dom/event-handler.js'
import BaseComponent from 'bootstrap/js/src/base-component.js'
import SelectorEngine from 'bootstrap/js/src/dom/selector-engine.js'
import Toaster, { ToasterPosition, ToasterType } from './toaster.js'
import axios from 'axios'
import selectorEngine from 'bootstrap/js/src/dom/selector-engine.js'

const NAME = 'import-product'
const DATA_KEY = 'bs.ImportProduct'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const SELECTOR_DATA_API_IMPORT_PRODUCT_UPLOAD = '[data-bs-target="import-product-upload"]'
const SELECTOR_DATA_API_IMPORT_PRODUCT_IMPORT = '[data-bs-target="import-product"]'
const SELECTOR_DATA_API_IMPORT_PRODUCT_STATUS = '[data-bs-target="import-product-status"]'

const ToasterTemplate = `<div class="toast align-items-center text-bg-primary border-0" role="alert" aria-live="assertive" aria-atomic="true">
              <div class="d-flex align-items-center p-2">
                <span class="toast-icon fs-5"></span>
                <div class="toast-body me-2 text-break"></div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Закрыть"></button>
              </div>
            </div>`

class ImportProduct extends BaseComponent {
  constructor (element, config) {
    super(element, config)

    this.toast = new Toaster({
      bg         : true,
      template   : ToasterTemplate,
      position   : ToasterPosition.TOP_END,
      defaultIcon: '<i class="bi bi-bell"></i>',
    })

    if (this._element) {
      this.progressBarContainer = SelectorEngine.findOne('#progress-container',
        this._element)

      if (this.progressBarContainer) {
        this.progressBarInner = SelectorEngine.findOne('.progress-bar',
          this.progressBarContainer)
      }

      this.csrfTokenElement = SelectorEngine.findOne('meta[name="csrf-token"]')
      this.csrfToken = this.csrfTokenElement
        ? this.csrfTokenElement.getAttribute('content')
        : ''

      this._element.setAttribute('novalidate', '')
      this._element.classList.add('needs-validation')
    }
  }

  static get NAME () {
    return NAME
  }

  handleUpload () {
    EventHandler.on(this._element, 'submit', async (event) => {
      event.preventDefault()

      if (!this._element.checkValidity()) {
        event.stopPropagation()
        const errors = { form: ['Пожалуйста, заполните все необходимые поля.'] }
        this._handleValidationErrors(errors)
        this.toast.create('', errors.form[0], { type: ToasterType.DANGER })
        return
      }

      const fileInput = SelectorEngine.findOne('input[type="file"]',
        this._element)

      if (!fileInput || fileInput.files.length === 0) {
        const errors = { file: ['Пожалуйста, выберите файл для загрузки.'] }
        this._handleValidationErrors(errors)
        this.toast.create('', errors.file[0], { type: ToasterType.DANGER })
        return
      }

      this._updateSubmitButtonStatus(true)
      this._showUploadProgressBar(true)

      const formData = new FormData(this._element)
      formData.append('file', fileInput.files[0])

      try {
        const response = await axios.post(this._element.action, formData, {
          headers         : {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-TOKEN'    : this.csrfToken,
            'Content-Type'    : 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent.lengthComputable) {
              const percentComplete = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total)

              this._updateProgressBar(percentComplete)
            }
          },
        })

        if (response.data.success) {
          this._showUploadProgressBar(false)
          this._handleValidationErrors({})
          this.toast.create('',
            response.data.message || 'Файл успешно загружен',
            { type: ToasterType.SUCCESS })

          if (response.data.redirect) {
            setInterval(() => window.location.href = response.data.redirect,
              1000)
          }
        }
      } catch (error) {
        this._handleValidationErrors(error.response?.data.errors || {})

        if (error.response && error.response.data.errors &&
          typeof error.response.data.errors === 'object' &&
          !Array.isArray(error.response.data.errors)) {
          let timeout = 4000

          for (const [field, messages] of Object.entries(
            error.response.data.errors)) {
            messages.forEach(message => {
              timeout += 2000
              this.toast.create('', message,
                { type: ToasterType.DANGER, delay: timeout })
            })
          }
        } else {
          this.toast.create('',
            error.response?.data?.message || 'Ошибка загрузки файла',
            { type: ToasterType.DANGER })
        }
      } finally {
        this._updateSubmitButtonStatus(false)
        this._showUploadProgressBar(false)
        this._handleValidationErrors({})
      }
    })
  }

  handleImport () {
    EventHandler.on(this._element, 'submit', async (event) => {
      event.preventDefault()

      const formData = new FormData(this._element)

      try {
        this._updateSubmitButtonStatus(true)

        const response = await axios.post(this._element.action, formData, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-TOKEN'    : this.csrfToken,
          },
        })

        if (response.data.success) {
          this.toast.create('',
            response.data.message || 'Файл успешно загружен',
            { type: ToasterType.SUCCESS })

          if (response.data.redirect) {
            setInterval(() => window.location.href = response.data.redirect,
              1000)
          }
        }
      } catch (error) {
        this._handleValidationErrors(error.response?.data.errors || {})

        if (error.response && error.response.data.errors &&
          typeof error.response.data.errors === 'object' &&
          !Array.isArray(error.response.data.errors)) {
          let timeout = 4000

          for (const [field, messages] of Object.entries(
            error.response.data.errors)) {
            messages.forEach(message => {
              timeout += 2000
              this.toast.create('', message,
                { type: ToasterType.DANGER, delay: timeout })
            })
          }
        } else {
          this.toast.create('',
            error.response?.data?.message || 'Ошибка загрузки файла',
            { type: ToasterType.DANGER })
        }
      } finally {
        this._updateSubmitButtonStatus(false)
      }
    })
  }

  handleStatus () {
    const intervalId = setInterval(async () => {
      const formData = new FormData(this._element)

      try {
        const response = await axios.post(this._element.action, formData, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-TOKEN'    : this.csrfToken,
          },
        })

        if (response.data.success) {

          if (response.data.import.status === 'completed') {
            clearInterval(intervalId)
            this.toast.create('',
              response.data.message || 'Импорт завершен.', { type: ToasterType.SUCCESS })

            //setTimeout(() => window.location.href = response.data.redirect, 2000)
          }

          if (response.data.percentage) {
            this._updateProgressBar(response.data.percentage)
          }
          if (response.data.import.status) {
            this._updateImportStatus(response.data.import.status)
          }

          const processed = selectorEngine.findOne('.processed',
            this.progressBarContainer)
          const total = selectorEngine.findOne('.total',
            this.progressBarContainer)

          if (processed && response.data.import.processed_rows) {
            processed.innerText = response.data.import.processed_rows
          }

          if (total && response.data.import.total_rows) {
            total.innerText = response.data.import.total_rows
          }
        }
      } catch (error) {
        this._handleValidationErrors(error.response?.data.errors || {})

        if (error.response && error.response.data.errors &&
          typeof error.response.data.errors === 'object' &&
          !Array.isArray(error.response.data.errors)) {
          let timeout = 4000

          for (const [field, messages] of Object.entries(
            error.response.data.errors)) {
            messages.forEach(message => {
              timeout += 2000
              this.toast.create('', message,
                { type: ToasterType.DANGER, delay: timeout })
            })
          }
        } else {
          this.toast.create('',
            error.response?.data?.message || 'Ошибка загрузки файла',
            { type: ToasterType.DANGER })
        }
      }
    }, 3000)
  }

  _updateImportStatus (status) {
    const statusElement = SelectorEngine.findOne('.status', this._element)

    if (statusElement) {
      statusElement.classList.remove('bg-warning', 'bg-info', 'bg-success',
        'bg-danger')

      switch (status) {
        case 'pending':
          statusElement.innerText = 'В ожидании'
          statusElement.classList.add('bg-secondary')
          break
        case 'processing':
          statusElement.innerText = 'В процессе'
          statusElement.classList.add('bg-warning')
          break
        case 'completed':
          statusElement.innerText = 'Завершён'
          statusElement.classList.add('bg-success')
          break
        case 'failed':
          statusElement.innerText = 'Ошибка'
          statusElement.classList.add('bg-danger')
          break
        default:
          statusElement.innerText = 'Неизвестный статус'
          statusElement.classList.add('bg-dark')
      }
    }
  }

  _handleValidationErrors (errors = {}) {
    const errorFields = SelectorEngine.find('.is-invalid, .invalid-feedback',
      this._element)

    errorFields.forEach(field => {
      if (field.classList.contains('is-invalid')) {
        field.classList.remove('is-invalid')
        field.classList.remove('is-valid')
      }

      if (field.classList.contains('invalid-feedback')) {
        field.remove()
      }
    })

    if (Object.keys(errors).length > 0) {
      this._element.classList.remove('was-validated')
    } else {
      this._element.classList.add('was-validated')
    }

    for (const field in errors) {
      const inputField = SelectorEngine.findOne(`[name="${field}"]`,
        this._element)

      if (inputField) {
        inputField.classList.add('is-invalid')
        inputField.setAttribute('aria-describedby', `error-${field}`)

        const errorDiv = document.createElement('div')

        errorDiv.classList.add('invalid-feedback', 'd-block')
        errorDiv.id = `error-${field}`
        errorDiv.textContent = errors[field][0]

        const inputGroup = inputField.closest('.input-group')

        if (inputGroup) {
          inputGroup.insertAdjacentElement('afterend', errorDiv)
        } else {
          inputField.insertAdjacentElement('afterend', errorDiv)
        }
      }
    }

    if (Object.keys(errors).length === 0) {
      this._element.classList.remove('was-validated')
    }
  }

  _updateSubmitButtonStatus (disabled) {
    const submitButton = SelectorEngine.findOne('button[type="submit"]',
      this._element)

    if (submitButton) {
      submitButton.disabled = disabled
    }
  }

  _updateProgressBar (percent) {
    if (this.progressBarInner) {
      this.progressBarInner.style.width = `${percent}%`
      this.progressBarInner.textContent = `${percent}%`
    }
  }

  _showUploadProgressBar (show) {
    const formOverlay = SelectorEngine.findOne('#overlay', this._element)

    if (show) {
      this.progressBarContainer.classList.remove('d-none')

      if (formOverlay) {
        formOverlay.classList.remove('d-none')
      }
    } else {
      this.progressBarContainer.classList.add('d-none')
      this._updateProgressBar(0)

      if (formOverlay) {
        formOverlay.classList.add('d-none')
      }
    }
  }

  _getDataForm (form) {
    return new FormData(form)
  }
}

EventHandler.on(window, 'load', () => {
  for (const selectorImportProductUpload of SelectorEngine.find(
    SELECTOR_DATA_API_IMPORT_PRODUCT_UPLOAD)) {
    ImportProduct.getOrCreateInstance(selectorImportProductUpload).
      handleUpload()
  }

  for (const selectorImportProductImport of SelectorEngine.find(
    SELECTOR_DATA_API_IMPORT_PRODUCT_IMPORT)) {
    ImportProduct.getOrCreateInstance(selectorImportProductImport).
      handleImport()
  }

  for (const selectorImportProductStatus of SelectorEngine.find(
    SELECTOR_DATA_API_IMPORT_PRODUCT_STATUS)) {
    ImportProduct.getOrCreateInstance(selectorImportProductStatus).
      handleStatus()
  }
})

export default ImportProduct
