/**
 * --------------------------------------------------------------------------
 * Vsyahimiya (v1.0.0): action.js
 * Licensed under MIT (https://vsyahimiya.ru/main/LICENSE.md)
 * --------------------------------------------------------------------------
 */

import EventHandler from 'bootstrap/js/src/dom/event-handler.js'
import BaseComponent from 'bootstrap/js/src/base-component.js'
import SelectorEngine from 'bootstrap/js/src/dom/selector-engine.js'
import Toaster, {
  ToasterType,
  ToasterPosition,
} from './toaster.js'
import AjaxForms from './ajax-forms.js'

const NAME = 'action'
const DATA_KEY = 'bs.action'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`
const EVENT_LOAD_DATA_API = `load${EVENT_KEY}${DATA_API_KEY}`

const SELECTOR_DATA_API_ACTION_DELETE = '[data-bs-target="action-delete"]'
const SELECTOR_DATA_API_ACTION_RESTORE = '[data-bs-target="action-restore"]'
const SELECTOR_DATA_API_ACTION_FORCE_DELETE = '[data-bs-target="action-force-delete"]'

const ToasterTemplate = `<div class="toast align-items-center text-bg-primary border-0" role="alert" aria-live="assertive" aria-atomic="true">
              <div class="d-flex align-items-center p-2">
                <span class="toast-icon fs-5"></span>
                <div class="toast-body me-2 text-break"></div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Закрыть"></button>
              </div>
            </div>`

class Action extends BaseComponent {
  constructor (element, config) {
    super(element, config)

    this.toast = new Toaster({
      bg         : true,
      template   : ToasterTemplate,
      position   : ToasterPosition.TOP_END,
      defaultIcon: '<i class="bi bi-bell"></i>',
    })
  }

  static get NAME () {
    return NAME
  }

  handleAction (actionType, confirmationMessage, successMessage, errorMessage, toastType) {
    if (this._element) {

      this._setTooltip()

      EventHandler.on(this._element, 'click', async (event) => {
        event.preventDefault()

        const confirmation = confirm(confirmationMessage)

        if (!confirmation) {
          return
        }

        this._updateButtonStatus(true)

        const result = await this._response(this._config.route, this._config.method)

        this._updateButtonStatus(false)

        if (result.success) {
          this.toast.create('', result.message ?? successMessage ?? 'Успех', { type: toastType })

          if (result.redirect) {
            setTimeout(() => window.location.href = result.redirect, 1000)
          }
        } else {
          this.toast.create('', result.error ?? result.message ?? errorMessage ?? 'Ошибка', { type: ToasterType.DANGER })
        }
      })
    }
  }

  handleDelete () {
    this.handleAction(
      'delete',
      'Вы уверены, что хотите удалить этот элемент?',
      'Элемент успешно удален',
      'Ошибка при удалении элемента',
      ToasterType.SUCCESS
    )
  }

  handleRestore () {
    this.handleAction(
      'restore',
      'Вы уверены, что хотите восстановить этот элемент?',
      'Элемент успешно восстановлен',
      'Ошибка при восстановлении элемента',
      ToasterType.SUCCESS
    )
  }
  handleForceDelete () {
    this.handleAction(
      'restore',
      'Вы уверены, что хотите удалить этот элемент навсегда?',
      'Элемент успешно удален',
      'Ошибка при удалении элемента',
      ToasterType.SUCCESS
    )
  }

  _setTooltip () {
    if (this._config.tooltip) {
      new bootstrap.Tooltip(this._element)
    }
  }

  _updateButtonStatus (disabled) {
    this._element.disabled = disabled
  }

  _response = async (url = '', method = 'POST', body = {}) => {
    try {
      const fetchResponse = await fetch(url, {
        method     : method,
        credentials: 'same-origin',
        headers    : {
          'Content-Type' : 'application/json',
          'Cache-Control': 'no-cache',
          'Charset'      : 'UTF-8',
          'X-CSRF-TOKEN' : SelectorEngine.findOne('meta[name="csrf-token"]').getAttribute('content'),
        },
        body       : JSON.stringify(body),
      })

      const data = await fetchResponse.json()

      return {
        ok        : fetchResponse.ok,
        status    : fetchResponse.status,
        statusText: fetchResponse.statusText,
        ...data,
      }
    } catch (error) {
      console.error('Ошибка:', error)
      return {
        ok        : false,
        status    : 500,
        statusText: 'Ошибка сети',
        message   : error.message,
      }
    }
  }
}

EventHandler.on(window, EVENT_LOAD_DATA_API, () => {
  for (const selectorDelete of SelectorEngine.find(SELECTOR_DATA_API_ACTION_DELETE)) {
    Action.getOrCreateInstance(selectorDelete).handleDelete()
  }

  for (const selectorRestore of SelectorEngine.find(SELECTOR_DATA_API_ACTION_RESTORE)) {
    Action.getOrCreateInstance(selectorRestore).handleRestore()
  }
  for (const selectorForceDelete of SelectorEngine.find(SELECTOR_DATA_API_ACTION_FORCE_DELETE)) {
    Action.getOrCreateInstance(selectorForceDelete).handleForceDelete()
  }
})

export default Action