/**
 * --------------------------------------------------------------------------
 * Vsyahimiya (v1.0.0): app.js
 * Licensed under MIT (https://vsyahimiya.ru/main/LICENSE.md)
 * --------------------------------------------------------------------------
 */

import Clock from './clock.js'
import './tooltip.js'
import AjaxForms from './ajax-forms.js'
import Action from './action.js'
import ImportProduct from './import-product.js'

export default {
  ImportProduct,
  AjaxForms,
  Action,
  Clock,
}
